export const USER_STATUS_MEMBER_DELETED = 3
export const VERIFICATION_LIST = ['facebook', 'google', 'email']

export enum AccessChannel {
  VintedGuide = 'vinted_guide',
  HcSearch = 'hc_search',
  HcTransaction = 'hc_transaction',
  HcTopics = 'hc_topics',
  ConversationTx = 'conversation_tx',
  ConversationNoTx = 'conversation_no',
  ProductLink = 'product_link',
  ExternalLink = 'external_link',
  DataExport = 'data_export',
}

export const CDN_ASSETS_URL = 'https://static-assets.vinted.com'
export const CDN_IMAGES_URL = `${CDN_ASSETS_URL}/images`

export const TWO_FA_CANCELLED_EVENT = 'twoFACancelled'
export const TWO_FA_COMPLETED_EVENT = 'twoFACompleted'

export enum TransparencyLawSubject {
  BuyerRights = 'buyerRights',
  ProfessionalSeller = 'professionalSeller',
}
